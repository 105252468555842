import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

export class Navigation extends React.Component {
  constructor (props) {
    super(props);
    this.state = { };
  }

  get className () {
    return classNames({
      'navigation': true,
      'navigation--open': this.props.open
    });
  }

  get year () {
    return this.props.website.years[0];
  }

  get oldYears () {
    let years = [...this.props.website.years];
    years.shift();
    return years;
  }

  get home () {
    if (this.props.website.language === 'fr') return 'Accueil'
    return 'Home'
  }

  render = () => (
    <nav className={this.className}>
      <div className="navigation__line">
        <Link to={`/${this.props.website.language}`}>{ this.home }</Link>
      </div>
      <div className="navigation__line navigation__line--show"> 
        <Link to={`/${this.props.website.language}/${this.year.year}/show`}>{ this.year.show.title }</Link>
        { this.year.grade5 && <Link to={`/${this.props.website.language}/${this.year.year}/5`}>5</Link> }
        { this.year.grade4 && <Link to={`/${this.props.website.language}/${this.year.year}/4`}>4</Link> }
        { this.year.grade3 && <Link to={`/${this.props.website.language}/${this.year.year}/3`}>3</Link> }
        { this.year.grade2 && <Link to={`/${this.props.website.language}/${this.year.year}/2`}>2</Link> }
        { this.year.grade1 && <Link to={`/${this.props.website.language}/${this.year.year}/1`}>1</Link> }
      </div>
      <div className="navigation__line">
        <Link to={`/${this.props.website.language}/about`}>{this.props.website.about.title}</Link>
      </div>
      <div className="navigation__line navigation__line--social">
        <a href="https://vimeo.com/user53349250" target="_blank">Vimeo</a>
        <a href="https://www.facebook.com/LaCambreModeS" target="_blank">Facebook</a>
        <a href="https://www.instagram.com/lacambremodes/" target="_blank">Instagram</a>
        <a href="https://www.lacambre.be/" target="_blank">lacambre.be</a>
        <a href={this.props.website.contactUsLink}>Contact</a>
      </div>

      { this.oldYears.map(year => {
        return (
          <div className="navigation__line navigation__line--old-show"> 
            <Link to={`/${this.props.website.language}/${year.year}/show`}>{ year.show.title }</Link>
            { year.grade5 && <Link to={`/${this.props.website.language}/${year.year}/5`}>5</Link> }
            { year.grade4 && <Link to={`/${this.props.website.language}/${year.year}/4`}>4</Link> }
            { year.grade3 && <Link to={`/${this.props.website.language}/${year.year}/3`}>3</Link> }
            { year.grade2 && <Link to={`/${this.props.website.language}/${year.year}/2`}>2</Link> }
            { year.grade1 && <Link to={`/${this.props.website.language}/${year.year}/1`}>1</Link> }
          </div>
        );
      }) }      

      <div className="navigation__line navigation__line--spacer">

      </div>
    </nav>
  )
}