import React from 'react';
import classNames from 'classnames';
import Logo from './logo.inline.svg';
import Hamburger from './hamburger.inline.svg';
import Previous from './previous.inline.svg';
import Next from './next.inline.svg';

import { Navigation } from '../navigation';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

export class Header extends React.Component {
  constructor () {
    super();
    this.state = {
      open: false
    };
  }

  get className () {
    return classNames({
      'container': true,
      'header': true,
      'header--overlay': this.props.overlay,
      'header--compact': this.props.compact,
      'header--open': this.state.open
    });
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  }

  render = () => (
    <>
      <Helmet>
        <html lang={this.props.website.language} />
      </Helmet>
      <header className={this.className}>
        <div className="header__language">
          <span>
            <Link to="/en" className={ this.props.website.language === 'en' ? 'active' : '' }>En</Link>
            <div className="header__language__spacer" />
            <Link to="/fr" className={ this.props.website.language === 'fr' ? 'active' : '' }>Fr</Link>
          </span>

          <div className="header__collection">
            <Link to={this.props.previous} className="header__collection__arrow"><Previous /></Link>
            <Link to={this.props.list}>List</Link>
            <Link to={this.props.next} className="header__collection__arrow"><Next /></Link>
          </div>
        </div>
        <div className="header__logo">
          <Link to={'/' + this.props.website.language}><Logo /></Link>
        </div>
        <div className="header__menu">
          <span onClick={this.toggle}>
            { this.state.open ? 'Close' : 'Menu' }
            <Hamburger />
          </span>
        </div>
      </header>
      <Navigation open={this.state.open} website={this.props.website} />
    </>
  )
}