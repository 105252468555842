import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/header';
import { CollectionInfo } from '../../components/collection-info';
import { CollectionGallery } from '../../components/collection-gallery';

export default class Page extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get className () {
    return classNames({
      'collection': true
    });
  }

  get website () {
    return this.props.pageContext.website;
  }

  get collection () {
    return this.props.pageContext.collection;
  }

  get grade () {
    return this.props.pageContext.grade;
  }

  get shareImageUrl () {
    var url = 'https:' + this.website.socialMediaImage.file.url;
    if (this.collection.collectionImages && this.collection.collectionImages.length > 0) {
      if (this.collection.collectionImages[0].file?.url) {
        url = this.collection.collectionImages[0].file?.url + '?w=1200&h=630&fit=fill&f=top';
      }
    }
    return url;
  }

  render = () => (
    <>
     <Helmet>
        <title>{`${this.grade} - ${this.collection.firstname} ${this.collection.lastname}`}</title>
        <meta name="description" content={this.website.description} />
        <meta property="og:image" content={this.shareImageUrl} />
      </Helmet>
      <Header
        website={this.website}
        overlay compact
        previous={this.props.pageContext.previous}
        next={this.props.pageContext.next}
        list={`/${this.website.language}/${this.props.pageContext.year}/${this.grade}`}
      />
      <main className={this.className}>
        <CollectionInfo {...this.collection} grade={this.grade} />
        <CollectionGallery images={this.collection.collectionImages || []} />
      </main>
    </>
  )
}