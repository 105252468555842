import React from 'react';
import classNames from 'classnames';
import Down from './down.inline.svg';

import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri} target="_blank">{children}</a>
    }
  }
};


export class CollectionInfo extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'collection-info': true
    });
  }

  down = () => {
    window.scrollBy({ top: window.innerHeight - 200, behavior: 'smooth' });
  }

  render = () => (
    <>
      <div className={this.className}>
      <div className="collection-info__grade"><span>{ this.props.grade }</span></div>
        <h2 className="collection-info__student">{this.props.firstname}<br/>{this.props.lastname}</h2>
        <h3 className="collection-info__collection">{this.props.collectionName}</h3>
        <div className="collection-info__body">
          { documentToReactComponents(this.props.body, options) }
        </div>
      </div>
      <div className="collection__down" onClick={this.down}>
        <Down />
      </div>
    </>
  )
}