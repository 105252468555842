import React from 'react';
import classNames from 'classnames';

export class CollectionGallery extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'collection-gallery': true
    });
  }

  componentDidMount () {
    document.documentElement.classList.add('collection-gallery__body');
  }

  componentWillUnmount () {
    document.documentElement.classList.remove('collection-gallery__body');
  }

  render = () => (
    <div className={this.className}>
      { this.props.images.map(image => (<div className="collection-gallery__slide">
        { image.file?.url && <img src={image.file.url + '?h=1500&q=80'} key={image.id} /> }
      </div>)) } 
    </div>
  )
}